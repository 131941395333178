import Vue from 'vue';
import URI from 'urijs'

import { config } from '@/plugins/env.config'
import { camelizeKeys, decamelizeKeys } from 'humps';

async function getPrescription(prescriptionExposureId, attemptNumber = 1) {  
  const getPrescriptionsUri = new URI(config.apiBaseUrl);
  getPrescriptionsUri.segment('ehr');
  getPrescriptionsUri.segment('v1');
  getPrescriptionsUri.segment('delivery');
  getPrescriptionsUri.segment('prescriptions');
  getPrescriptionsUri.addSearch('prescription_exposure_id', prescriptionExposureId);

  
  try {
    const response = await Vue.prototype.$http.get(getPrescriptionsUri.toString());
    return camelizeKeys(response);
  } catch(error) {
    const maxAttempts = config.getTokenMaxAttempts
    const attemptDelayInSeconds = config.getTokenAttemptDelayInSeconds

    const response = error.response
    const status = response.status

    if (status === 500 && attemptNumber < maxAttempts) {
      await new Promise(resolve => setTimeout(resolve, attemptDelayInSeconds * 1000))
      return getPrescription(prescriptionExposureId, attemptNumber + 1)
    }
    
    return status;
  }
}

async function createPrescriptionDeliveryLog(payload, attemptNumber = 1) {
  const createPrescriptionsUri = new URI(config.apiBaseUrl);
  createPrescriptionsUri.segment('ehr');
  createPrescriptionsUri.segment('v1');
  createPrescriptionsUri.segment('delivery');

  try {
    const response = await Vue.prototype.$http.post(createPrescriptionsUri.toString(), decamelizeKeys(payload));
    sessionStorage.setItem('prescriptionDeliveryLog', JSON.stringify(camelizeKeys(payload)));
    sessionStorage.setItem('products', []);
    sessionStorage.setItem('providers', []);
    return response;
  } catch (error) {
    const maxAttempts = config.getTokenMaxAttempts
    const attemptDelayInSeconds = config.getTokenAttemptDelayInSeconds

    const response = error.response
    const status = response.status

    if (status === 500 && attemptNumber < maxAttempts) {
      await new Promise(resolve => setTimeout(resolve, attemptDelayInSeconds * 1000))
      return createPrescriptionDeliveryLog(payload, attemptNumber + 1)
    }

    throw error;  
  }
}

export default {
  getPrescription,
  createPrescriptionDeliveryLog,
}
