import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

function loadLangMessages() {
  const langs = require.context("../locale", true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages = {}
  langs.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const lang = matched[1]
      messages[lang] = {...messages[lang], ...langs(key)}
    }
  })
  return messages
}

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'es',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'es',
  messages: loadLangMessages(),
});
