const config = {
  apiBaseUrl: process.env.VUE_APP_OSIGU_API_BASE_URL || 'https://api.dev.osigu.com',
  getTokenMaxAttempts: process.env.VUE_APP_GET_TOKEN_MAX_ATTEMPTS || 5,
  getTokenAttemptDelayInSeconds: process.env.VUE_APP_GET_TOKEN_ATTEMPTS_DELAY_IN_SECONDS || 1,
  clientId: process.env.VUE_APP_CLIENT_ID || 'ehr-api',
  clientSecret: process.env.VUE_APP_CLIENT_SECRET || '4110ab29-f110-451c-bc8d-43c0baf5c24e',
  flagSendLogsTrackingApi: process.env.VUE_APP_FLAG_SEND_LOGS_TRACKING_API || true,
}

export { config }
