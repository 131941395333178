<template>
  <svg viewBox="0 0 16 16">
    <circle class="st0"
      cx="8"
      cy="8"
      r="7.5" />
    <path class="st1"
      d="M9.4,13.2L9.4,13.2L9.4,13.2c0.9-1,2-2.6,2.1-4.8H8.4v6.1c3.4-0.2,6.1-3.1,6.1-6.5c0-3.1-2.2-5.8-5.1-6.4
		c1.1,1.1,2.8,3.1,2.9,6h1.1c0.2,0,0.4,0.2,0.4,0.4c0,0.2-0.2,0.4-0.4,0.4h-1.1c0,2.7-2.3,5.4-2.3,5.4l0,0c-0.2,0.2-0.4,0.2-0.6,0
		C9.2,13.6,9.2,13.4,9.4,13.2 M5.9,2.4L5.9,2.4c-0.9,1.1-2,2.8-2.2,5.2H1.5c0.2-3.3,2.8-5.9,6.1-6.1v6.1h-3c0.1-2.1,1.1-3.7,2-4.7
		c0.1-0.2,0.1-0.4,0-0.6C6.3,2.2,6,2.2,5.9,2.4z M8.4,1.9c1,0.8,2.9,2.7,3,5.7h-3V1.9z M3.7,8.4c0.1,2.9,1.8,4.9,2.9,6
		c-2.8-0.6-4.9-3-5.1-6H3.7z M7.6,14.2c-1.7-1.4-2.9-3.4-3-5.7h3V14.2z" />
  </svg>
</template>

<script>
export default {
  name: 'WebsiteIcon',
};
</script>

<style lang="scss" scoped>
.st0 {
  fill: #ffffff;
}
.st1 {
  fill: #b3b7bd;
}
</style>