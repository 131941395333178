import Vue from 'vue';
import * as VeeValidate from 'vee-validate';

import App from '@/App.vue';
import router from '@/router';

import store from '@/plugins/vuex';
import vuetify from '@/plugins/vuetify';
import validations from '@/plugins/validations';
import i18n from '@/plugins/i18n';
import Interceptors from '@/plugins/interceptors';

import '@/styles/application.scss';

import { ValidationProvider } from 'vee-validate/dist/vee-validate.full.esm';
import { ValidationObserver } from 'vee-validate';

Vue.config.productionTip = false;

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

Vue.use(VeeValidate);
Vue.use(validations);

const interceptors = Interceptors(store)

new Vue({
  router,
  store,
  vuetify,
  i18n,
  interceptors,
  render: h => h(App)
}).$mount('#app');
