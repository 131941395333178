import URI from 'urijs'
import axios from 'axios'
import { camelizeKeys } from 'humps'
import { config } from '@/plugins/env.config'

async function getClientToken(attemptNumber = 1) {
  const getTokenUri = new URI(config.apiBaseUrl)
  getTokenUri.segment('v1')
  getTokenUri.segment('oauth')
  getTokenUri.segment('token')
  getTokenUri.addSearch('grant_type', 'client_credentials')

  const getTokenHeaders = {
    Authorization: `Basic ${Buffer.from(`${config.clientId}:${config.clientSecret}`).toString('base64')}`
  }

  let tokenResponse = null;

  try {
    tokenResponse = await axios.post(getTokenUri.toString(), {}, {
      headers: getTokenHeaders
    })
  } catch (error) {
    const maxAttempts = config.getTokenMaxAttempts
    const attemptDelayInSeconds = config.getTokenAttemptDelayInSeconds

    const response = error.response
    const status = response.status

    if (status === 500 && attemptNumber < maxAttempts) {
      await new Promise(resolve => setTimeout(resolve, attemptDelayInSeconds * 1000))
    }

    throw error
  }

  const token = camelizeKeys(tokenResponse.data)
  if (!token || !token.accessToken || !token.tokenType) {
    throw new Error('Invalid server response')
  }

  const {
    accessToken,
    token_type,
    expires_in,
    scope,
    extensions
  } = token;

  return {
    accessToken,
    token_type,
    expires_in,
    scope,
    extensions
  }
}

export default {
  getClientToken,
}
