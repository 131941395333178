import Vue from 'vue';

import { config } from '@/plugins/env.config'
import URI from 'urijs';

async function sendEventsDelivery(payload, attemptNumber = 1) {
    const createEventsUri = new URI(config.apiBaseUrl);
    createEventsUri.segment('events')

    try {

        await Vue.prototype.$http.post(createEventsUri, payload);

    } catch (error) {
        const maxAttempts = config.getTokenMaxAttempts
        const attemptDelayInSeconds = config.getTokenAttemptDelayInSeconds

        const response = error.response
        const status = response.status

        if (status === 500 && attemptNumber < maxAttempts) {
            await new Promise(resolve => setTimeout(resolve, attemptDelayInSeconds * 1000))
            return sendEventsDelivery(payload, attemptNumber + 1)
        }
        throw error;
    }
}

export default {
    sendEventsDelivery
}