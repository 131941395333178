import { extend, setInteractionMode } from 'vee-validate';
import {
  required,
  email,
  numeric,
  max,
  alpha_spaces,
} from 'vee-validate/dist/rules';

import i18n from '@/plugins/i18n';

const LOWER_CASE_EMAIL_REGEX = /^[a-z0-9._-]+@[a-z0-9._-]+\.[a-z0-9-.]+$/;

setInteractionMode('eager');

export default () => {
  extend('required', {
    ...required,
    message: () => i18n.t('error.requiredField'),
  });
  extend('email', {
    ...email,
    message: () => i18n.t('error.invalidEmail'),
  });
  extend('numeric', {
    ...numeric,
    message: () => i18n.t('error.invalidNumeric'),
  });
  extend('max', {
    ...max,
    message: () => i18n.t('error.invalidMaxLength'),
  });
  extend('alpha_spaces', {
    ...alpha_spaces,
    message: () => i18n.t('error.invalidAlphaSpaces'),
  });
  extend('regex', {
    validate(value) {
      return (LOWER_CASE_EMAIL_REGEX.test(value))
    },
    params: ['value'],
    message: () => i18n.t('error.invalidLowercase'),
  });
};
