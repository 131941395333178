import {
  SET_TOKEN,
  CLIENT_TOKEN_ERROR,
  CLIENT_TOKEN_SUCCESS,
} from '../mutation-types';

export default {
  [SET_TOKEN](state, accessToken) {
    state.accessToken = accessToken;
  },
  [CLIENT_TOKEN_SUCCESS] (state, parameters) {
    state.clientToken = parameters.accessToken
  },
  [CLIENT_TOKEN_ERROR] (state) {
    state.clientToken = ''
  },
}
