import Vue from 'vue';
import Vuetify from 'vuetify/lib';

import OsiguLogo from '@/assets/images/svg/OsiguLogo';
import InstagramIcon from '@/assets/images/svg/InstagramIcon.vue'
import LinkedInIcon from '@/assets/images/svg/LinkedInIcon.vue'
import WebsiteIcon from '@/assets/images/svg/WebsiteIcon.vue'

Vue.use(Vuetify);

const primaryColor = '#528ef7';
const secondaryColor = '#1f3261';
const errorColor = '#FF726D';
const blackColor = '#2a2a2a';
const grayColor = '#828282';
const coralColor = '#ff726d'

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: primaryColor,
        secondary: secondaryColor,
        error: errorColor,
        accent: errorColor,
        'osg-black': blackColor,
        'osg-gray': grayColor,
        'osg-coral': coralColor
      }
    }
  },
  icons: {
    values: {
      'osigu-logo': {
        OsiguLogo
      },
      'osg-instagram': {
        component: InstagramIcon
      },
      'osg-linkedin': {
        component: LinkedInIcon
      },
      'osg-website': {
        component: WebsiteIcon
      },
    }
  }
});
