import store from '@/plugins/vuex'

const Dispensing = () => import('@/components/dispensing');
const RequestedOrder = () => import('@/components/requested-order');
const PageNotFound = () => import('@/components/PageNotFound.vue');
const Loading = () => import('@/components/loading');

const ifNotPrescriptionInformation = (to, from, next) => {
  next();
}

const ifPrescriptionInformation = (to, from, next) => {
  if(store.getters['prescription/prescriptionDeliveryLog']) {
    next('/requested-order');
    return;
  }
  if(store.getters['prescription/prescription']) {
    next();
    return;
  }
  next('/error')
}

const ifCreatePrescriptionDeliveryLog = (to, from, next) => {
  if(store.getters['prescription/prescriptionDeliveryLog']) {
    next();
    return;
  }
  next('/error')
}

const routes = [
  {
    path: '/delivery/:UUID',
    component: Loading,
    beforeEnter: ifNotPrescriptionInformation,
    props: true,
  },
  {
    path: '/',
    name: 'Delivery',
    component: Dispensing,
    beforeEnter: ifPrescriptionInformation,
  },
  {
    path: '/requested-order',
    name: 'Requested Order',
    component: RequestedOrder,
    beforeEnter: ifCreatePrescriptionDeliveryLog,
  },
  {
    path: '*',
    name: 'error',
    exact: true,
    component: PageNotFound,
  }
]

export default routes;