import {
  SET_PRESCRIPTION,
  SET_PRESCRIPTION_EXPOSURE_ID,
  SET_PRESCRIPTION_PRODUCTS,
  SET_PRESCRIPTION_PROVIDERS,
  SET_PRESCRIPTION_CURRENT_PROVIDER,
  SET_PRESCRIPTION_PROVIDER_DELIVERY_CITIES,
  SET_PRESCRIPTION_PROVIDER_PAYMENT_METHODS,
  SET_PRESCRIPTION_PROVIDER_FORMS,
  CREATE_PRESCRIPTION_DELIVERY_LOG,
  CREATE_PRESCRIPTION_DELIVERY_LOG_ERROR,
  SET_REQUESTED_ORDER_MESSAGE,
  CREATE_NON_ASSOCIATED_PRODUCTS,
  SET_ERROR,
  SET_FORM_DATA_COLLECTED,
  ADD_PRODUCT,
  REMOVE_PRODUCT,
  SET_TRANSACTION_ID
} from '../mutation-types';

export default {
  [SET_PRESCRIPTION](state, payload) {
    state.prescription = payload;
  },
  [SET_PRESCRIPTION_EXPOSURE_ID](state, payload) {
    state.prescriptionExposureId = payload;
  },
  [SET_PRESCRIPTION_PRODUCTS](state, payload) {
    state.products = payload;
  },
  [SET_PRESCRIPTION_PROVIDERS](state, payload) {
    state.providers = payload;
  },
  [SET_PRESCRIPTION_CURRENT_PROVIDER](state, payload) {
    state.provider = payload;
  },
  [SET_PRESCRIPTION_PROVIDER_DELIVERY_CITIES](state, payload) {
    state.deliveryCities = payload;
  },
  [SET_PRESCRIPTION_PROVIDER_PAYMENT_METHODS](state, payload) {
    state.paymentMethods = payload;
  },
  [SET_PRESCRIPTION_PROVIDER_FORMS](state, payload){
    state.forms = payload;
  },
  [CREATE_PRESCRIPTION_DELIVERY_LOG](state, payload) {
    state.prescriptionDeliveryLog = payload;
  },
  [CREATE_PRESCRIPTION_DELIVERY_LOG_ERROR](state, payload) {
    state.createPrescriptionDeliveryLogError = payload;
  },
  [SET_REQUESTED_ORDER_MESSAGE](state,payload){
    state.requestedOrderMessage = payload
  },
  [CREATE_NON_ASSOCIATED_PRODUCTS](state,payload){
    state.nonAssociatedProducts = payload;
  },
  [SET_ERROR](state,payload){
    state.error = payload
  },
  [SET_FORM_DATA_COLLECTED](state, payload){
    state.formDataCollected = payload;
  },
  [ADD_PRODUCT](state, payload){
    state.selectedProduct[payload.index] = payload.product;
  },
  [REMOVE_PRODUCT](state, payload){
    delete state.selectedProduct[payload];
  },
  [SET_TRANSACTION_ID](state, payload){
    state.transactionId = payload;
  }
}
