const decamelizeObject = (source, key) => {
    if (!source && !key) return;

    if (Array.isArray(source)) {
        const decamelizedArray = source.map(item => decamelizeObject(item));
        return key ? { [decamelize(key)]: decamelizedArray } : decamelizedArray;
    } else if (source && typeof source === 'object') {
        let decamelizedObject = {};
        Object.keys(source).forEach(k => {
            decamelizedObject = { ...decamelizedObject, ...decamelizeObject(source[k], k) }
        })
        return decamelizedObject;
    } else {
        return key ? { [decamelize(key)]: source } : source;
    }
}

const decamelize = (str, separator  = '_') => {
    
    return str
        .replace(/([a-z\d])([A-Z])/g, '$1' + separator + '$2')
        .replace(/([A-Z]+)([A-Z][a-z\d]+)/g, '$1' + separator + '$2')
        .toLowerCase();
}

const validateUUID = (UUID) => {

    const regexExpUUID = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
    const isValidUUID = regexExpUUID.test(UUID);
    return isValidUUID;
}

export { decamelizeObject, validateUUID }