

export const generateHeaderEventDelivery = (payload) => {
    const { event, transactionId } = payload;

    const applicationName = "delivery-web"
    const timestamp = new Date().toISOString();
    const eventName = `delivery_${event}`;

    return { applicationName, timestamp, eventName, transactionId };
}