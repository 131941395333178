export default {
  prescription: JSON.parse(sessionStorage.getItem('prescription')) || null,
  prescriptionExposureId: sessionStorage.getItem('prescriptionExposureId') || null,
  products: (sessionStorage.getItem('products')) ? JSON.parse(sessionStorage.getItem('products')) : [],
  selectedProduct:[],
  providers: (sessionStorage.getItem('providers')) ? JSON.parse(sessionStorage.getItem('providers')) : [],
  provider: [],
  deliveryCities: [],
  paymentMethods: [],
  forms:[],
  createPrescriptionDeliveryLogError: false,
  prescriptionDeliveryLog: JSON.parse(sessionStorage.getItem('prescriptionDeliveryLog')) || null,
  requestedOrderMessage:null,
  nonAssociatedProducts:null,
  error:null,
  formDataCollected:{},
  transactionId: null
}
