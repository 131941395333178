import authService from '@/services/auth.service';
import {
  SET_TOKEN,
  CLIENT_TOKEN_ERROR,
  CLIENT_TOKEN_SUCCESS,
} from '../mutation-types';

const requestClientToken = ({ commit }) => {
  return new Promise((resolve, reject) => {
    authService.getClientToken()
    .then(res => {
      sessionStorage.setItem('clientToken', res.accessToken)
      commit(CLIENT_TOKEN_SUCCESS, res)
      resolve(res)
    })
    .catch(error => {
      commit(CLIENT_TOKEN_ERROR)
      sessionStorage.removeItem('clientToken')
      reject(error)
    })
  })
}

const setToken = ({ commit }, payload) => {
  commit(SET_TOKEN, payload);
}

export default {
  requestClientToken,
  setToken,
}
