import Vue from 'vue'
import axios from 'axios'

import router from '@/router'

import { SET_TOKEN } from '@/store/mutation-types';

export default (vuexStore) => {
  const axiosInstance = axios.create();

  axiosInstance.interceptors.request.use((config) => {
    const clientToken = vuexStore.getters['auth/clientToken'];

    if (clientToken) {
      config.headers.Authorization = `Bearer ${clientToken}`;
    }

    config.headers['accept-language'] = 'es'; //TODO This value is temporary. At the moment there is no support for other languages.
    return config
  }, (error) => Promise.reject(error))

  axiosInstance.interceptors.response.use((response) => response,
    async (error) => {
      const originalRequest = error.config;
      const response = error.response;
      const clientToken = vuexStore.getters['auth/clientToken'];

      if (response.status !== 401) {
        return Promise.reject(error)
      }

      if (originalRequest._retry) {
        router.push('/error')
      }

      try {
        vuexStore.commit(`auth/${SET_TOKEN}`, clientToken);
        return axiosInstance(originalRequest)
      } catch (e) {
        router.push('/error')
      }
    })

  Vue.prototype.$http = axiosInstance
}
